import { createApp } from 'vue'
import App from './components/App.vue'
import './index.css'
import router from "./router/index"
import store from "./js/store/vuex-store"
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App)


Sentry.init({
  app,
  dsn: "https://05c5555d2855423ca1247c9c5bbf966d@o1289449.ingest.sentry.io/6507924",
  debug: false,
  maxBreadcrumbs: 50,
  //integrations: [
  //  new BrowserTracing({
  //    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //    tracingOrigins: ["localhost","localhost:8888", "app.project-shark.com", /^\//],
  //  }),
  //],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  //tracesSampleRate: 1.0,
});

app.use(router).use(store)
app.mount('#app')
