import {
  createRouter,
  createWebHistory
}
from 'vue-router'

// Containers
import DocumentationContainer from '../components/DocumentationContainer.vue'
import ContentsContainer from '../components/ContentsContainer.vue'
import SplashContainer from '../components/SplashContainer.vue'


// Navigation
import SideBar from '../components/SideBar.vue'
import DocumentationNavigation from '../components/DocumentationNavigation.vue'

// Components
import Projects from '../components/Projects.vue'
import Tasks from '../components/tasks/Tasks.vue'
import DependencyGraph from '../components/dependency_graph/DependencyGraph.vue'
import Resources from '../components/resources/Resources.vue'
import Errors from '../components/errors/Errors.vue'
import TaskSchedules from '../components/schedules/TaskSchedules.vue'
import DocumentationContent from '../components/DocumentationContent.vue'

// Temporary Components
import ComingSoon from '../components/ComingSoon.vue'

// Articles
import InstructionPlaceholder from '../articles/InstructionPlaceholder.vue'
import DescriptionPlaceholder from '../articles/DescriptionPlaceholder.vue'

const routes = [
  {
    path: '/',
    name: 'coming-soon',
    component: ComingSoon,
  },
  {
    path: '/splash',
    name: 'splash',
    component: SplashContainer,
  },
  {
    path: '/projects',
    component: ContentsContainer,
    children: [{
      path: '',
      name: 'projects',
      components: {
        contents: Projects,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/projects'
        }
      }
    }, ],
  },
  {
    path: '/tasks',
    component: ContentsContainer,
    children: [{
      path: '',
      name: 'tasks',
      components: {
        contents: Tasks,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/tasks'
        }
      }
    }, ],
  },
  {
    path: '/dependencies',
    component: ContentsContainer,
    children: [{
      path: '',
      name: 'dependencies',
      components: {
        contents: DependencyGraph,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/dependencies'
        }
      }
    }, ],
  },
  {
    path: '/resources',
    component: ContentsContainer,
    children: [{
      name: 'resources',
      path: '',
      components: {
        contents: Resources,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/resources'
        }
      }
    }, ],
  },
  {
    path: '/schedule',
    component: ContentsContainer,
    children: [{
      name: 'schedule',
      path: '',
      components: {
        contents: TaskSchedules,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/schedule'
        }
      }
    }, ],
  },
  {
    path: '/documentation',
    component: DocumentationContainer,
    children: [{
        name: 'documentation',
        path: '',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'getting-started',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'modeling-uncertainty-distributions',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'describing-tasks',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'importing-project',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'resources-dependencies',
        components: {
          contents: InstructionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'interpreting-project-schedule',
        components: {
          contents: DescriptionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'modeling-real-world-scenarios',
        components: {
          contents: DescriptionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'simulation-modeling-algorithms',
        components: {
          contents: DescriptionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
      {
        path: 'reference',
        components: {
          contents: DescriptionPlaceholder,
          sidebar: SideBar,
          documentation_navigation: DocumentationNavigation
        },
        props: {
          sidebar: {
            selection_path: '/documentation'
          }
        }
      },
    ],
  },
  {
    path: '/errors',
    component: ContentsContainer,
    children: [{
      name: 'errors',
      path: '',
      components: {
        contents: Errors,
        sidebar: SideBar
      },
      props: {
        sidebar: {
          selection_path: '/errors'
        }
      }
    }, ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
