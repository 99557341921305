import _ from 'lodash';
import moment from 'moment';

export default function(i){

  // user input with some of the project fields blank
  const input = _.cloneDeep(i)

  // this default object is not a valid project object by itself
  // we use the value of this object to fill in the missing values
  // of the user input
  const DEFAULT = {
    overview: {
      simulations: 100
    },
    resource_calendar: {
      start: {
        day:   moment().date(),       // default to the current day of the month if none specified
        month: moment().month() + 1,  // add one. moment js indexs months starting at 0 (wtf?)
        year:  moment().year(),
      },
      include: { weekdays: [], dates: [] },
      exclude: { weekdays: [], dates: [] },
    },
    tasks: {
      absent: {
        duration: {
          estimate: 0,
          distribution: { type: 'exact', parameters: { } }
        }
      }
    }
  }

  // overview configuration
  const overview = function(i){
    return { name: name(i), description: description(i), simulations: simulations(i) }
  }

  const name = function(i){
    return i['overview']['name']
  }

  const description = function(i){
    return i['overview']['description']
  }

  const simulations = function(i){
    if( _.has(i,'overview.simulations') ){
      return i['overview']['simulations']
    }
    return DEFAULT['overview']['simulations']
  }

  const resources = function(i){
    let r = _.cloneDeep(i)['resources']
    // step through each one of the resources
    // populate default params of the calendar
    _.each(_.keys(r), (k) => {
      r[k]['calendar'] = _.merge(_.cloneDeep(DEFAULT['resource_calendar']), _.cloneDeep(r[k]['calendar']))
    })
    return r;
  }

  // tasks functions
  const tasks = function(i){
    let t = _.cloneDeep(i)['tasks']
    // step through each one of the tasks
    // if the duration object is missing, replace it with the default
    // otherwise ignore
    _.each(_.keys(t), (k) => {
      t[k]['duration'] = duration(t[k])
    })
    return t;
  }

  const duration = function(task){
    if ( duration_missing(task) ){
      return DEFAULT['tasks']['absent']['duration']
    }else {
      return _.cloneDeep( task['duration'] )
    }
  }

  const duration_missing = function(task){
    if ( !_.has(task, 'duration') ){
      return true
    }
    return false
  }

  return _.cloneDeep( _.extend({}, input, { overview: overview(input), resources: resources(input), tasks: tasks(input) } ) )
}
