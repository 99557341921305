<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div class="grid grid-cols-3 gap-6" data-component="Projects">
  <div class="col-span-2">
    <ul role="list" class="space-y-3">
      <li v-for="project in projects" :key="project.name" class="col-span-1 bg-white rounded-lg shadow-lg divide-y divide-gray-200 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-105">
        <Project
          v-bind:id="project.id"
          v-bind:data_target="project.data_target"
          v-bind:name="project.name"
          v-bind:description="project.description"
          v-bind:role="project.role"
          v-bind:email="project.email"
          v-bind:telephone="project.telephone"
          v-bind:yaml="project.yaml"
        ></Project>
      </li>
    </ul>

    <ul role="list" class="space-y-3">
      <li v-for="project in registered_projects()" class="col-span-1 bg-white rounded-lg shadow-lg divide-y divide-gray-200 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-105">
        <RegisteredProject></RegisteredProject>
      </li>
    </ul>
  </div>
  <div class="...">
    <div class="bg-white rounded-lg shadow-lg p-4">
      <span class="ml-3 flex flex-col">
        <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
        <span id="privacy-setting-1-label" class="block text-sm font-medium">Project File Import </span>
        <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
        <span class="block text-sm py-4">
          <input class="block w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600" aria-describedby="file_input_help" id="file_input" type="file" ref="doc">
        </span>
      </span>

      <div class="flex justify-center p-4 ...">
        <button @click="import_project" type="button" class="inline-flex items-center px-6 py-3 border border-gray-200 shadow-lg text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">

        <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
        </svg>

        Import
        </button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import _ from 'lodash'
import Project from './Project.vue'
import RegisteredProject from './RegisteredProject.vue'

import data_warehouse_migration from '../projects/data_warehouse_migration.yml?raw'
import construction from '../projects/construction.yml?raw'

const projects = [
  {
    id: 'building-construction',
    data_target: '#building-construction',
    name: 'Building Construction',
    description: 'Small construction project',
    yaml: data_warehouse_migration,
  },
  {
    id: 'software-migration',
    data_target: '#software-migration',
    name: 'Data Base Migration',
    description: 'Realistic IT tranformation project',
    yaml: data_warehouse_migration,
  },
]

export default {
  components: {
    Project,
    RegisteredProject,
  },
  methods: {
    registered_projects() {
      return this.$store.getters.registered_projects
    },
    import_project() {
      const selected_file = this.$refs.doc.files[0];

      // if the user clicks import before a file is selected, just do nothing
      if ( _.isUndefined(selected_file) ){
        return;
      }

      console.log(selected_file);
      const reader = new FileReader();
      reader.onload = (res) => {
        console.log(res.target.result);
      };
      reader.onerror = (err) => console.log(err);
      reader.readAsText(selected_file);
    },
  },
  setup() {
    return {
      projects,
    }
  },
  mounted() {

    // register the example projects to the store
    const example_projects = [
      {
        'id':'data_warehouse_migration',
        'file': '-',
        'yaml': data_warehouse_migration,
        'type': 'example',
      },
      {
        'id':'construction',
        'file': '-',
        'yaml': construction,
        'type': 'example',
      },
    ]

    // get the vuex store
    const store = this.$store

    _.each(example_projects, (project) => {
      // load each project metadata into the store
      store.dispatch({
        type: 'registerProject',
        project_metadata: project,
      })
    })

    return;
  }
}
</script>
