<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <button @click="select_project" class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="truncate">
      <div class="flex items-center space-x-3">
        <h3 class="text-gray-900 text-sm font-medium truncate">Registered Project</h3>
      </div>
    </div>
  </button>
</template>

<script>

export default {
  components: { },
  setup() {
    return { }
  },
}
</script>
