import Vuex    from 'vuex'
import _       from 'lodash'
import yaml    from 'js-yaml'
import Promise from 'bluebird'
import axios   from 'axios'
import * as Sentry from '@sentry/vue'

// Services
import yamlValidationErrors from '../services/yaml-validation-errors.js'
import schemaValidationErrors from '../services/schema-validation-errors.js'
import dependsOnValidationErrors from '../services/depends-on-validation-errors.js'
import computeDependencyGraph from '../services/compute-dependency-graph.js'
import computeEstimatedProjectSpan from '../services/compute-estimated-project-span.js'
import fillDefaultProjectValues from '../services/fill-default-project-values.js'

// Proxy Object Reactive Helpers
import { toRaw } from 'vue'

const convertToJs = function (data) {
  return new Promise((resolve) => {
    resolve( yaml.safeLoad(data) );
  });
};

const areErrorsPresent = function (state) {
  return !_.every(_.values( state['errors'] ), function(o) { return _.isEmpty(o); } )
};

const areErrorsAbsent = function (state) {
  return !areErrorsPresent(state)
};

const empty_state = {
  url: '',
  user: {},
  registered_projects: [],
  project: {},
  estimated_project_span: {},
  dependency_graph: {},
  task_schedules: {},
  errors: {
    yaml: [],
    schema: [],
    cycle: [],
    depends_on: [],
    trial_project_size: [],
  },
};

const store = new Vuex.Store({
  state: _.cloneDeep(empty_state),
  mutations: {
    loadUser (state, payload) {
      _.extend(state, { user: payload.user });
    },
    loadProject (state, payload) {
      _.extend(state, { project: payload.project });
    },
    loadErrors (state, payload) {
      _.extend(state, { errors: _.cloneDeep(payload.errors) });
    },
    loadUrl (state, payload) {
      _.extend(state, { url: payload.url });
    },
    register_project (state, payload) {
      _.extend(state, {registered_projects: _.union( state['registered_projects'], [payload.project_metadata]) } );
    },
    update_dependency_graph (state, payload ) {
      _.extend(state, { dependency_graph: _.cloneDeep(payload.dependency_graph) } );
    },
    update_estimated_project_span (state, payload ) {
      _.extend( state['estimated_project_span'], _.cloneDeep(payload.project_span));
    },
    update_task_schedules (state, payload ) {
      _.extend(state['task_schedules'], _.cloneDeep(payload.task_schedules));
    },
    update_errors (state, payload ) {
      _.extend(state, { errors: _.cloneDeep(payload.errors) });
    },
  },
  getters: {
    navigation (state) {
      return state['navigation']
    },
    errors (state) {
      return state['errors']
    },
    registered_projects (state) {
      return state['registered_projects']
    },
    project (state) {
      return state['project']
    },
    resources (state) {
      return state['project']['resources']
    },
    tasks (state) {
      return state['project']['tasks']
    },
    dependency_graph (state) {
      return state['dependency_graph']
    },
    estimated_project_span (state) {
      return state['estimated_project_span']
    },
    user_email (state) {
      return state['user']['email']
    },
    user_status (state) {
      return state['user']['status']
    },
    overview (state) {
      return state['project']['overview']
    },
    url (state) {
      return state['url']
    },
    task_schedules (state) {
      return state['task_schedules']
    },
  },
  actions: {
    importUser ({ dispatch, commit }, input) {
      commit({
        type: 'loadUser',
        user: input.user
      })
    },
    loadUrl ({ dispatch, commit }, input) {
      commit({
        type: 'loadUrl',
        url: input.url
      })
    },
    registerProject ({ dispatch, commit }, input) {
      commit({
        type: 'register_project',
        project_metadata: input.project_metadata
      })
    },
    async importProject ({ dispatch, commit, getters, state }, input) {

      // NOTES ON SENTRY TRACING:
      // * Do not attempt tracing of any code in the core or lib directory.
      // * KEEP SENTRY PROFILING RESTRICTED TO THE MAIN THREAD. (COMPONENTS, DISPLAYS, SERVICES)
      // * We want the unit tests to run cleanly and not report data back to sentry.io
      // * Most of this core and lib dir code supports project simulations and runs in a
      //   serverless function. This can make tracing it super complicated since
      //   js scope handover to background processes is not straightforward.
      // * To improve code in the core and lib dir. Use a dedicated profiler in development.

      // transaction and span are used for instrumenting the process
      //let transaction; let span;

      // declare the sentry.io transaction
      //transaction = Sentry.startTransaction({ name: "compute-project" });


      // clear any pre existing errors in the state
      // restor the error state to the default
      commit({ type: 'update_errors', errors: _.cloneDeep(empty_state['errors']) })

      // clear existing dependency graph
      commit({ type: 'update_dependency_graph',  dependency_graph: {} })

      // incoming project yaml
      const project_yaml = input.project

      // measure yaml validation time span with apm
      //span = transaction.startChild({ op: "validation.yaml", description: "fn.yamlValidationErrors" });

      // validate yaml
      const yaml_errors = await yamlValidationErrors(project_yaml)

      // end span
      //span.finish();

      // commit the errors to the state and halt the import
      if (!_.isEmpty(yaml_errors)) {
        commit({
          type: 'loadErrors',
          errors: { yaml: yaml_errors, schema: [], depends_on: [], trial_project_size: [] }
        })
      }

      // do not continue computations if there are errors
      if ( areErrorsPresent(state) ) {
        return false;
      }

      // convert yaml to js
      let project_js;
      project_js = await convertToJs(project_yaml)

      // measure yaml validation time span with apm
      //span = transaction.startChild({ op: "validation.schema", description: "fn.schemaValidationErrors" });

      // validate incoming project schema
      // schema validation errors
      const schema_errors = await schemaValidationErrors(project_js)

      // end span
      //span.finish();

      // commit the errors to the state and halt the import
      if (!_.isEmpty(schema_errors)) {
        commit({
          type: 'loadErrors',
          errors: { yaml: [], schema: schema_errors, depends_on: [], trial_project_size: [] }
        })
      }

      // do not continue computations if there are errors
      if ( areErrorsPresent(state) ) {
        return false;
      }

      // validate the number of project tasks for trial users
      const trail_project_size_errors = []

      // get the total number ot tasks in the project
      const task_count = _.size(_.keys(project_js['tasks']))

      // restrict the number of tasks available for trial users
      const TRIAL_TASK_LIMIT = 10;

      // check if the user is trialer
      const is_trial_user = _.isEqual(getters.user_status, 'trial')

      // has the user exceeded the trial limit
      const trial_limit_exceeded = (task_count > TRIAL_TASK_LIMIT)

      if(is_trial_user && trial_limit_exceeded ){
        trail_project_size_errors.push({
          message: 'trial project errors message',
          task_ids:'-'
        })
      }

      // commit the errors to the state and halt the import
      if (!_.isEmpty(trail_project_size_errors)) {
        commit({
          type: 'loadErrors',
          errors: { yaml: [], schema: [], depends_on: [], trial_project_size: trail_project_size_errors }
        })
      }

      // do not continue computations if there are errors
      if ( areErrorsPresent(state) ) {
        return false;
      }

      // validate task dependency references
      // task dependency reference errors
      //
      // this includes checking for task cycle errors
      const depends_on_errors = await dependsOnValidationErrors(project_js)

      // commit the errors to the state and halt the import
      if (!_.isEmpty(depends_on_errors)) {
        commit({
          type: 'loadErrors',
          errors: { yaml: [], schema: [], depends_on: depends_on_errors, trial_project_size: [] }
        })
      }

      // do not continue computations if there are errors
      if ( areErrorsPresent(state) ) {
        return false;
      }

      // get the project object as the user has inputed it
      // this does not include the inferred default values
      const user_project_js = await convertToJs(input.project)

      // We now have the full project js object with all fields
      // and nested fields populated
      // If the user left an optional field empty, this function will populate
      // with the correct default value
      // this ensures that down stream functions can make assumptions
      // about the structure of the project file
      project_js = await fillDefaultProjectValues(user_project_js)

      // load new validated project into state tree
      commit({ type: 'loadProject', project: project_js })

      // capture project js for downstream processing
      // we access the project from the vuex store for consistency of access pattern.
      // notes about vue ProxyObjects:
      //   * When you get an object from from the vuex store by default it returns a ProxyObject
      //   * ProxyObects are the original JS job but with all sorts of Vue framework helper
      //     methods attached for management of DOM reactivty based features.
      //   * In this case we want to avoid the ProxyObject wrapper since it creates searilization problems
      //     when we attempt to send the object over post message to web workers
      //   * The imported toRaw() helper gives us back the original object with attched vuejs framework functions
      const project = toRaw(getters.project)

      // Compute Estimated Project Span
      // ------------------------
      // Running the full simulation gives us the actual span of the project
      // that the user configures. However, there may be display features, like
      // the resource calendars that require knowing an approximate start and finish
      // date of the overall project without demanding that we run the full simulation
      // in the background jobs.
      // The purpose of this function is to return this estimated project span
      // based off the following user input:
      // * task estimated lengths
      // * resource calendars

      // compute the project span - an object with start, finish, and project length
      const project_span = await computeEstimatedProjectSpan(project)

      // load the updated graph state
      commit({ type: 'update_estimated_project_span', project_span })

      // Compute Dependency Graph
      // ------------------------
      // compute the project dependnecy graph
      const graph = await computeDependencyGraph( project['tasks'] )

      // load the updated graph state
      commit({ type: 'update_dependency_graph', dependency_graph: graph })

      // Netlify Function Simulation
      // ------------------------
      // the url redirect config in netlify is not straightforward. we decided to stick with '/.netlify/functions/*'
      // path prefix to avoid this additional moving part.
      const netlify_simulation_results = await axios.post('/.netlify/functions/project-simulations', project).then(function (response) {
        return Promise.resolve(response['data']);
      })

      // load the updated task schedules
      commit({
        type: 'update_task_schedules',
        task_schedules: netlify_simulation_results['task_schedules'],
      })

      // end project validation and  simulation trasaction
      //transaction.finish();
    },
  }
})

export default store
