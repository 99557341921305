import _ from 'lodash';
import moment from 'moment';

// check if date a is on or before date b
export default function(a, b){

  if( a['year'] > b['year'] ){
    return false;
  }

  if( a['year'] < b['year'] ){
    return true;
  }

  if( a['year'] == b['year'] ){

    if( a['month'] > b['month'] ){
      return false;
    }

    if( a['month'] < b['month'] ){
      return true;
    }

    if( a['month'] == b['month'] ){

      if( a['day'] > b['day'] ){
        return false;
      }

      if( a['day'] < b['day'] ){
        return true;
      }

      if( a['day'] == b['day'] ){
        return true;
      }
    }
  }
};
