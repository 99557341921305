<template>
  <div v-bind:id="id" class="bg-white overflow-hidden shadow-lg rounded-lg divide-y divide-gray-200" data-component="TaskSchedule">
    <div class="px-4 py-5 sm:px-6">
      <div class="grid grid-rows-1">
        <div class="grid grid-cols-6 gap-4">
          <div class="col-span-4">
            <h3 class="text-gray-900 text-lg font-medium">{{ name }}</h3>
          </div>
          <div class="col-span-2 flex justify-center">
            <!-- Custom Toggle Switch -->
            <div
              @click="toggleDisplaySims"
              class="relative inline-flex h-5 w-10 cursor-pointer items-center justify-center rounded-full transition-colors duration-200 ease-in-out"
              :class="{ 'bg-gray-200': display_sims, 'bg-gray-400': !display_sims }"
              data-component="ToggleSwitch"
            >
              <span class="sr-only">Use setting</span>
              <span
                class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                :class="{ 'translate-x-5': display_sims, 'translate-x-0': !display_sims }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-bind:id="aggregated_schedule_selector" class="px-4 py-4 sm:p-6">
      <ActiveDays
        :selector="aggregated_schedule_selector"
        :heatmap_input="aggregated_schedule_input()"
      ></ActiveDays>
    </div>

    <div v-if="display_sims" class="px-4 py-4 sm:p-6" data-component="ActiveDays">
      <div v-for="sim_id in sim_ids()" v-bind:id="sim_selector(sim_id)" class="py-1">
        <ActiveDays
          :selector="sim_selector(sim_id)"
          :heatmap_input="simulated_task_schedule_input(sim_id)"
        ></ActiveDays>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import ActiveDays from './ActiveDays.vue';
</script>

<script>
export default {
  props: ['id', 'name', 'calendar_span', 'aggregated_schedule', 'simulated_schedules'],
  components: {
    ActiveDays,
  },
  data() {
    return {
      display_sims: false
    };
  },
  computed: {
    aggregated_schedule_selector() {
      const base_id = this.id;
      return `${base_id}_aggregated_schedule`;
    },
  },
  methods: {
    toggleDisplaySims() {
      this.display_sims = !this.display_sims;
    },
    aggregated_schedule_input() {
      return {
        heatmap_selector: this.aggregated_schedule_selector,
        display_months: true,
        opacity_domain: true,
        calendar_span: this.calendar_span,
        active_days: this.aggregated_schedule['active_days']
      };
    },
    simulated_task_schedule_input(sim_id) {
      return {
        heatmap_selector: this.sim_selector(sim_id),
        display_months: false,
        opacity_domain: false,
        calendar_span: this.calendar_span,
        active_days: this.simulated_schedules[sim_id]['active_days']
      };
    },
    sim_ids() {
      return _.keys(this.simulated_schedules);
    },
    sim_selector(sim_id) {
      return `${sim_id}_simulated_task_schedule`;
    },
  },
}
</script>
